@import "./variables.module.scss";
.review {
  .topNavbar {
    position: relative;
    &::after {
      content: "";
      background-image: url($bg-base-path + "/papercuteffact.svg");
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      height: 60px;
      z-index: 1;
      bottom: -60px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      background-repeat: no-repeat;
      background-size: cover;
      @include extra_large {
        bottom: -40px;
      }
    }
    .header {
      background-color: $white-color;
      padding: 80px 0 40px;
      @include large_device {
        padding: 40px 0;
      }
      .image {
        img {
          object-fit: contain;
          height: auto;
        }
      }
      .bottomStar {
        margin-top: 30px;
        position: relative;
        top: -60px;
        @include extra_large {
          top: -30px;
        }
        @include large_device {
          top: -10px;
        }
        @include medium_device {
          margin-top: 0;
        }
        & div {
          color: $theme-custom-color;
        }
      }
      .cheapestDetail {
        @include medium_device {
          margin-bottom: 30px;
        }
        .orderNow {
          position: relative;
          width: 240px;
          @include mobile_device {
            width: 100%;
          }
          &::after {
            content: "";
            position: absolute;
            background-image: url($bg-base-path + "/review/order-now-arrow.gif");
            width: 70px;
            height: 40px;
            background-size: 70px;
            background-repeat: no-repeat;
            bottom: -40px;
            right: -60px;
            -webkit-transform: rotate(-160deg);
            transform: rotate(-160deg);
            @include mobile_device {
              background-size: 50px;
              bottom: -20px;
              right: -50px;
            }
            @include extra_small {
              content: none;
            }
          }
          @include extra_small {
            width: 100%;
          }
        }
        .title {
          color: $theme-custom-color;
          @include up_large {
            font-size: 35px;
            line-height: 44px;
          }
          @include mobile_device {
            font-size: 24px;
            line-height: 35px;
            margin-bottom: 20px;
          }
          span {
            &::before {
              @include up_large {
                bottom: 12px;
              }
              @include mobile_device {
                bottom: 0;
              }
            }
          }
        }
        .desc {
          font-weight: 500;
          width: 80%;
          margin-bottom: 50px;
          font-size: 18px;
          @include up_large {
            margin-bottom: 30px;
          }
          @include mobile_device {
            margin-bottom: 20px;
            width: 100%;
            font-size: 16px;
            text-align: center;
          }
        }
      }
      .rating {
        .left {
          border: 1px solid #c4c4c4;
        }
        .right {
          .name,
          .rate {
            color: $theme-custom-color;
          }
        }
      }
    }
  }
  .latestReview {
    margin-bottom: 50px;
    padding-top: 120px;
    @include extra_large {
      padding-bottom: 0;
      padding-top: 80px;
    }
    @include medium_device {
      padding-bottom: 0;
    }
    @include mobile_device {
      padding-top: 60px;
    }
  }
  .nav {
    width: 90%;
    margin: 0 auto;
    justify-content: center;
    @include up_large {
      width: 100%;
    }
    .navItem {
      .paperType,
      .sourceType {
        background: $white-color;
        border: 1px solid transparent;
        border-radius: 50px;
        padding: 20px 30px;
        line-height: 22px;
        margin: 0 10px 20px;
        color: $theme-custom-color;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
        text-transform: capitalize;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        @include up_large {
          padding: 12px 15px;
        }
        @include large_device {
          margin: 0 8px 8px;
          font-size: 14px;
        }
        @include medium_device {
          padding: 8px 14px;
          margin: 0 5px 10px;
        }
        @include mobile_device {
          padding: 6px 10px;
          font-size: 13px;
        }
        &[class*="active"],
        &:hover {
          border-color: #fbd501;
        }
      }
    }
  }
  .tabContent {
    margin-top: 40px;
    .viewMoreContainer{
      display: flex;
      justify-content: center;
      padding: 16px;
    }
    @include mobile_device {
      margin-top: 20px;
    }
    .cardColumns {
      -webkit-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 30px;
      column-gap: 30px;
      justify-content: space-between;
      @include large_device {
        -webkit-column-gap: 20px;
        column-gap: 20px;
      }
      @include medium_device {
        -webkit-column-count: 2;
        column-count: 2;
      }
      @include mobile_device {
        -webkit-column-count: 1;
        column-count: 1;
      }
      &.cardLoader {
        column-count: 1;
      }
      .card {
        break-inside: avoid;
        @include large_device {
          margin-bottom: 20px;
        }
        @include mobile_device {
          width: 100%;
          margin-bottom: 15px;
        }
        &.writerBox {
          padding: 20px;
          @include large_device {
            padding: 12px 12px 20px;
          }
          .writerImg {
            position: relative;
            overflow: hidden;
            height: 255px;
            @include large_device {
              height: 200px;
            }
            img {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 3px;
            }
          }
          .writerDetail {
            margin: 0;
            padding-top: 16px;
            @include extra_large {
              padding-bottom: 20px;
            }
          }
        }
        .cardBody {
          .bottom {
            position: absolute;
            bottom: 20px;
            left: 20px;
            right: 20px;
          }
        }
        border: none;
        margin-bottom: 30px;
        -webkit-box-shadow: 5px 8px 15px rgba(61, 90, 112, 0.1);
        box-shadow: 5px 8px 15px rgba(61, 90, 112, 0.1);
        .tabRating {
          position: relative;
          padding-bottom: 15px;
          @include mobile_device {
            padding-bottom: 0;
          }
          .left {
            margin-right: 15px;
            width: 50px;
            height: 50px;
            @include large_device {
              width: 40px;
              height: 40px;
            }
            .text {
              line-height: 50px;
              text-align: center;
              border-radius: 50px;
              background: $body-color;
              font-size: 16px;
              color: $theme-custom-color;
              text-transform: uppercase;
            }
            img {
              width: 50px;
              height: 50px;
              @include large_device {
                width: 40px;
                height: 40px;
              }
            }
          }
          .right {
            width: calc(100% - 65px);
            @include mobile_device {
              width: calc(100% - 55px);
            }
            .name {
              font-size: 16px;
              text-transform: capitalize;
              font-weight: 700;
              line-height: 24px;
              margin-bottom: 5px;
              @include large_device {
                margin-bottom: 0;
                font-size: 14px;
              }
              @include mobile_device {
                margin-bottom: 4px;
              }
            }
            .rate {
              img {
                margin-right: 8px;
                @include mobile_device {
                  margin-right: 3px;
                  width: 12px;
                }
              }
            }
          }
          &:before {
            content: "";
            background-image: url($bg-base-path + "/review/quote.svg");
            width: 15px;
            height: 12px;
            position: absolute;
            background-repeat: no-repeat;
            bottom: -15px;
          }
          &:after {
            content: "";
            background-image: url($bg-base-path + "/review/border.svg");
            background-repeat: no-repeat;
            width: 95%;
            height: 1px;
            position: absolute;
            right: 0;
            bottom: -10px;
            background-size: cover;
          }
        }
        .desc {
          width: 100%;
          margin-top: 35px;
          font-size: 18px;
          margin-bottom: 40px;
          line-height: 26px;
          @include extra_large {
            font-size: 16px;
          }
          @include large_device {
            font-size: 14px;
            line-height: 24px;
          }
          @include mobile_device {
            margin-top: 30px;
          }
        }
        .text {
          font-size: 15px;
          color: $desc-font;
          font-weight: 500;
          @include large_device {
            font-size: 13px;
          }
        }
        .writerDetail {
          padding-top: 30px;
          position: relative;
          margin: 0 10px;
          .name {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            margin-top: 0;
            margin-bottom: 0;
            @include extra_large {
              font-size: 16px;
            }
          }
          .btn {
            @include extra_large {
              padding: 8px 16px;
              position: relative;
              top: 25px;
              width: 100%;
            }
          }
          .star {
            & > span {
              & > span {
                margin-right: 8px;
              }
            }
          }
          .education {
            color: #888888;
            font-size: 14px;
            margin-bottom: 25px;
          }
          .subDetail {
            margin-top: 16px;
            margin-bottom: 16px;
            li {
              margin-bottom: 8px;
              width: 50%;
              span {
                margin-left: 10px;
                font-size: 16px;
                @include extra_large {
                  margin-left: 6px;
                  margin-right: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
  .hireTopWriter {
    background-color: $theme-color;
    margin-bottom: 140px;
    padding: 0;
    position: relative;
    @include large_device {
      text-align: center;
      margin-bottom: 60px;
      margin-top: 100px;
    }
    &::before,
    &::after {
      content: "";
      background-image: url($bg-base-path + "/bannerBorder.svg");
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      height: 68px;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &::before {
      top: -65px;
    }
    &::after {
      bottom: -65px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .banner {
      padding-top: 40px;
      padding-bottom: 20px;
      position: relative;
      @include extra_large {
        padding-top: 20px;
      }
      &::before {
        content: "";
        background-image: url($bg-base-path + "/review/vector1.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 555px;
        height: 474px;
        left: 0;
        @include extra_large {
          width: 485px;
        }
      }
      &::after {
        content: "";
        background-image: url($bg-base-path + "/review/vector2.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 680px;
        height: 660px;
        left: 15%;
        top: -40px;
        bottom: 0;
        z-index: 0;
        @include extra_large {
          width: 490px;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
      &::before,
      &::after {
        @include medium_device {
          content: none;
        }
      }
      .left {
        position: relative;
        z-index: 1;
        @include medium_device {
          display: -ms-flexbox;
          display: flex;
          -webkit-column-gap: 15px;
          column-gap: 15px;
        }
        img {
          height: auto;
        }
      }
      .btn {
        z-index: 1;
      }
    }
    .title {
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      color: $white-color;
      margin-bottom: 32px;
      @include extra_large {
        margin-bottom: 15px;
        font-size: 28px;
      }
      @include medium_device {
        line-height: 38px;
        margin-top: 15px;
      }
      @include mobile_device {
        font-size: 24px;
      }
    }
    .desc {
      color: $white-color;
    }
  }
  .contentWrapper {
    padding-bottom: 0;
  }

  .faq {
    padding-bottom: 100px;
  }
}
.combinedContentWrapper {
  max-height: 420px;
  overflow: auto;
  max-width: 1320px;
  margin: 150px auto 80px;
  @include large_device {
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @include mobile_device {
    margin-bottom: 40px;
  }
  &.empty {
    display: none;
  }
}
